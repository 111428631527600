@mixin breakpoint($name) {
  $rule: map-get((
    'xs': 'only screen and (min-width: #{$xs})',
    'sm': 'only screen and (min-width: #{$sm})',
    'md': 'only screen and (min-width: #{$md})',
    'lg': 'only screen and (min-width: #{$lg})',
    'xl': 'only screen and (min-width: #{$xl})',
    'xs-down': 'only screen and (max-width: #{$xs - 0.02})',
    'sm-down': 'only screen and (max-width: #{$sm - 0.02})',
    'md-down': 'only screen and (max-width: #{$md - 0.02})',
    'lg-down': 'only screen and (max-width: #{$lg - 0.02})',
    'xl-down': 'only screen and (max-width: #{$xl - 0.02})',
    // Specific devices
    'iphone-x': 'only screen and '
              + '(device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3)',
    'iphone-8': 'only screen and '
              + '(device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2)',
    'iphone-8-plus': 'only screen and '
                   + '(device-width : 414px) and (device-height : 736px) and (-webkit-device-pixel-ratio : 3)'
  ), $name);
  @media #{$rule} {
    @content;
  }
}

//@mixin breakpoint-xs-down {
//  @media screen and (max-width: $xs - 1) {@content;}
//}
@mixin breakpoint-xs {
  @media screen and (min-width: $xs) {
    @content;
  }
}

@mixin breakpoint-sm {
  @media screen and (min-width: $sm) {
    @content;
  }
}

@mixin breakpoint-md {
  @media screen and (min-width: $md) {
    @content;
  }
}

@mixin breakpoint-lg {
  @media screen and (min-width: $lg) {
    @content;
  }
}

@mixin breakpoint-md-down {
  @media screen and (max-width: $md - 1) {
    @content;
  }
}

//@mixin breakpoint-xl {
//  @media screen and (min-width: $xl) {@content;}
//}

// Alternative breakpoints
@mixin breakpoint-sm-alt {
  @media screen and (min-width: $sm-alt) {
    @content;
  }
}

@mixin breakpoint-md-alt {
  @media screen and (min-width: $md-alt) {
    @content;
  }
}

@mixin breakpoint-lg-alt {
  @media screen and (min-width: $lg-alt) {
    @content;
  }
}

@mixin breakpoint-xl-alt {
  @media screen and (min-width: $xl-alt) {
    @content;
  }
}

@mixin breakpoint-sm-alt-down {
  @media screen and (max-width: $sm-alt - 1) {
    @content;
  }
}

@mixin breakpoint-md-alt-down {
  @media screen and (max-width: $md-alt - 1) {
    @content;
  }
}

@mixin breakpoint-lg-alt-down {
  @media screen and (max-width: $lg-alt - 1) {
    @content;
  }
}

@mixin breakpoint-xl-alt-down {
  @media screen and (max-width: $xl-alt - 1) {
    @content;
  }
}

// Human-readable breakpoints :)
@mixin breakpoint-1600 {
  @media screen and (max-width: 1600px) {
    @content;
  }
}

@mixin breakpoint-1560 {
  @media screen and (max-width: 1560px) {
    @content;
  }
}

@mixin breakpoint-1520 {
  @media screen and (max-width: 1520px) {
    @content;
  }
}

@mixin breakpoint-1440 {
  @media screen and (max-width: 1440px) {
    @content;
  }
}

@mixin breakpoint-1420 {
  @media screen and (max-width: 1420px) {
    @content;
  }
}

@mixin breakpoint-1400 {
  @media screen and (max-width: 1400px) {
    @content;
  }
}

@mixin breakpoint-1380 {
  @media screen and (max-width: 1380px) {
    @content;
  }
}

@mixin breakpoint-1300 {
  @media screen and (max-width: 1300px) {
    @content;
  }
}

@mixin breakpoint-1280 {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

@mixin breakpoint-1200 {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin between-992-1280 {
  @media screen and (max-width: 1280px) and (min-width: 992px) {
    @content;
  }
}

@mixin between-900-1280 {
  @media screen and (max-width: 1280px) and (min-width: 900px) {
    @content;
  }
}

@mixin between-900-1300 {
  @media screen and (max-width: 1300px) and (min-width: 900px) {
    @content;
  }
}

@mixin between-1024-1280 {
  @media screen and (max-width: 1280px) and (min-width: 1024px) {
    @content;
  }
}

@mixin between-992-1024 {
  @media screen and (max-width: 1024px) and (min-width: 992px) {
    @content;
  }
}

@mixin between-992-1124 {
  @media screen and (max-width: 1124px) and (min-width: 992px) {
    @content;
  }
}

@mixin between-1024-1240 {
  @media screen and (min-width: 1024px) and (max-width: 1240px) {
    @content;
  }
}

@mixin breakpoint-1130 {
  @media screen and (max-width: 1130px) {
    @content;
  }
}

@mixin breakpoint-1100 {
  @media screen and (max-width: 1100px) {
    @content;
  }
}

@mixin breakpoint-1056 {
  @media screen and (max-width: 1056px) {
    @content;
  }
}

@mixin breakpoint-1040 {
  @media screen and (max-width: 1040px) {
    @content;
  }
}

@mixin breakpoint-1024 {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin breakpoint-1023 {
  @media screen and (max-width: 1023px) {
    @content;
  }
}

@mixin breakpoint-992 {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin breakpoint-930 {
  @media screen and (max-width: 930px) {
    @content;
  }
}

@mixin breakpoint-900 {
  @media screen and (max-width: 900px) {
    @content;
  }
}

@mixin breakpoint-840 {
  @media screen and (max-width: 840px) {
    @content;
  }
}

@mixin breakpoint-830 {
  @media screen and (max-width: 830px) {
    @content;
  }
}

@mixin breakpoint-820 {
  @media screen and (max-width: 820px) {
    @content;
  }
}

@mixin breakpoint-768 {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin breakpoint-730 {
  @media screen and (max-width: 730px) {
    @content;
  }
}

@mixin breakpoint-700 {
  @media screen and (max-width: 700px) {
    @content;
  }
}

@mixin breakpoint-695 {
  @media screen and (max-width: 695px) {
    @content;
  }
}

@mixin breakpoint-680 {
  @media screen and (max-width: 680px) {
    @content;
  }
}

@mixin breakpoint-660 {
  @media screen and (max-width: 660px) {
    @content;
  }
}

@mixin breakpoint-640 {
  @media screen and (max-width: 640px) {
    @content;
  }
}

@mixin breakpoint-600 {
  @media screen and (max-width: 600px) {
    @content;
  }
}

@mixin breakpoint-576 {
  @media screen and (max-width: 576px) {
    @content;
  }
}

@mixin breakpoint-540 {
  @media screen and (max-width: 540px) {
    @content;
  }
}

@mixin breakpoint-500 {
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin breakpoint-450 {
  @media screen and (max-width: 450px) {
    @content;
  }
}

@mixin breakpoint-400 {
  @media screen and (max-width: 400px) {
    @content;
  }
}

@mixin breakpoint-380 {
  @media screen and (max-width: 380px) {
    @content;
  }
}

@mixin breakpoint-320 {
  @media screen and (max-width: 320px) {
    @content;
  }
}

// Chrome Emulator Inspired Breakpoints
@mixin mobile {
  @media screen and (max-width: $mobile-lg-gl) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $mobile-lg-gl+1) and (max-width: $tablet-gl) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: $tablet-gl+1) and (max-width: $laptop-md-gl) {
    @content;
  }
}

@mixin laptop-sm {
  @media (min-width: $tablet-gl+1) and (max-width: $laptop-sm-gl) {
    @content;
  }
}

@mixin laptop-md {
  @media (min-width: $laptop-sm-gl+1) and (max-width: $laptop-md-gl) {
    @content;
  }
}

@mixin breakpoint-laptop-md {
  @media screen and (max-width: $laptop-md-gl) {
    @content;
  }
}

@mixin laptop-lg {
  @media (min-width: $laptop-md-gl+1) and (max-width: $laptop-lg-gl) {
    @content;
  }
}

@mixin breakpoint-laptop-lg {
  @media screen and (max-width: $laptop-lg-gl) {
    @content;
  }
}

@mixin laptop-md-to-laptop-lg {
  @media (min-width: $laptop-sm-gl+1) and (max-width: $laptop-lg-gl) {
    @content;
  }
}

@mixin tablet-to-laptop-lg {
  @media (min-width: $tablet-gl+1) and (max-width: $laptop-lg-gl) {
    @content;
  }
}

@mixin laptop-4k {
  @media (min-width: $laptop-lg-gl+1) and (max-width: $laptop-4k-gl) {
    @content;
  }
}
