.settings {

  &-card {
    position: relative;
    margin-bottom: 24px;
    padding: 24px;
    @include card();

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &._centered {
        justify-content: center;
      }
      
      &-title {
        font-weight: 600;
        font-size: 22px;
        line-height: 22px;
        position: relative;

        &._active {
          color: $color-primary !important;
        }
      }
    }

    &-subheader {
      color: #595959;
      font-size: 14px;
      margin-top: 5px;
  
      ._tooltip {
        border-bottom: 2px dotted #c4cdd5;
      }
    }

    &-body {
      margin-top: 24px;
      p {
        margin-bottom: 0;
      }
    }

    &-footer {
      margin-top: 24px;
      display: flex;
      flex-flow: row;
      justify-content: flex-end;
    }
  }


  &-control {
    position: relative;
    padding-top: 10px;
    margin-bottom: 16px;

    label {
      position: absolute;
      background-color: #fff;
      box-shadow: 0 0 2px 1px #fff;
      font-family: 'Roboto', 'Helvetica Neue', sans-serif;
      color: #000;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      top: -1px;
      left: 5px;
      padding: 0 5px;
      z-index: 1002;
    }

    mat-select, input, select {
      height: 46px;
      padding: 0.65rem 0.75rem;
      width: 100%;
      border: 1px solid #ced4da;
      border-radius: 4px;
    }

    &._small {
      mat-select, input, select {
        height: 30px;
        padding: 6px;
        font-size: 14px;
      }
    }

    ng-select {
      .ng-select-container .ng-value-container .ng-input > input {
        height: 34px;
      }
      &.ng-select.ng-select-single .ng-select-container {
        border: 1px solid #ced4da !important;
        height: 46px !important;
      }
    }
  }
}
