.pf-table {
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;

  @include breakpoint-600 {
    padding-top: 0 !important;
    margin-top: 0 !important;

    &:not(.mat-table) {
      margin-bottom: 0 !important;
    }

    .mat-row {
      height: auto !important;
    }
    .mat-row:hover {
      background: #fff !important;
    }

    thead {
      display: none !important;
    }
  
    tbody tr {
      position: relative;
      display: block;
      height: auto;
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    td,
    td.mat-cell {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      padding: 5px !important;
      border-bottom: 0;
      min-height: auto;
      text-align: left !important;
      width: 100% !important;
      max-width: none !important;
      min-width: auto !important;
      filter: none !important;
  
      &::before {
        content: attr(data-title);
        font-weight: bold;
        margin-right: 15px;
      }

      &.mat-column-buffer,
      &.mat-column-buffer-2 {
        display: none;
      }

      ._local-Coupon-chip {
        grid-column: 2;
      }

      &.mat-column-name {
        display: block;
        &::before {
          display: none;
        }
      }
      
      .cdk-drag-handle {
        padding-left: 0;
        &::before {
          display: none;
        }
      }
  
      .root-cell {
        min-height: auto;
      }
    }

    tr.detail-row-coupons,
    tr.detail-row-orders {
      padding: 0;
      height: auto;
    }
    td.mat-column-expandedCouponDetail,
    td.mat-column-expandedOrderDetail {
      display: block;
      width: 100% !important;
      margin-left: 0;
      padding: 0 !important;
      box-shadow: none;
      &::before {
        display: none;
      }
      .orders-list {
        width: 100%;
        margin-left: 0;
        padding: 0;
        box-shadow: none;
        // .order-details {
        //   width: 100%;
        // }
      }
    }

    .mat-column-statusChange,
    .mat-column-action,
    .mat-column-actions {
      display: flex !important;
      justify-content: flex-end;
      border: none !important;
  
      &::before {
        display: none;
      }

      .pf-button {
        display: none;
      }

      .mat-menu-trigger {
        position: absolute;
        top: 5px;
        right: 0;
        z-index: 1;
      }
        
      
    }
  }

  thead th {
    font-size: 12px;
    font-weight: 500;
  }
  thead th,
  tbody td {
    max-width: 300px;
    border-color: rgba(0, 0, 0, 0.12);
  }
  ._no-data-row {
    &:hover {
      background: transparent !important;
      cursor: default;
    }
    td {
      border: none !important;
      display: table-cell !important;
    }
    &::before {
      display: none !important;
    }
  }
  ._no-data-img {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
  }

  .schedule-block {
    display: grid;
    gap: 0 5px;
    grid-template-columns: 21px auto;
    grid-template-rows: auto auto;

    &-icon {
      grid-column: 1 / 2;
      grid-row: 1 / 3;
      align-self: center;
      font-size: 21px;
      color: var(--color-warning);
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      margin-right: 5px;
      height: 19px;
    }

    &-title {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
  
    &-description {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      display: block;
      margin-top: -2px;
      color: #666;
    }
  }


  ._local-validity {
    position: relative;
    width: 20px;
    one-icon[name='alert-circle'] {
      font-size: 22px;
      color: #f00;
      cursor: pointer;
    }
    &-messages {
      display: none;
      position: absolute;
      top: 30px;
      left: 10px;
      z-index: 10;
      background: #fff;
      padding: 10px;
      width: max-content;
      border-radius: 6px;
      transform: translateX(-50%);
      box-shadow: 0 0 10px rgb(0, 0, 0, 0.5);
    }

    &:hover {
      ._local-validity-messages {
        display: block;
      }
    }
  }

  tr._colspan-title-row {
    height: 24px;
    
    td:first-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    & + tr td {
      padding-top: 0 !important;
    }

    a {
      color: var(--color-primary-new) !important;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  ._line-clamp {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  ._send-to {
    display: grid;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    cursor: default;

    &:hover {
      white-space: initial;
      overflow: visible;
    }

    ._send-to-item {
      display: inline-block;
      font-size: 11px;
      margin: 2px 5px 2px 0;
      color: #ffffff;
      background-color: #333333;
      border-radius: 12px;
      margin-right: 5px;
      padding: 1px 8px;
    }
  }
}

.cdk-column-status,
.cdk-column-sending_status {
  width: 90px;
}

._schedule-row {
  td {
    width: 300px;
  }
}
