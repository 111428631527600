// Deprecated, use pf-badge below
.badge {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-size: 12.8px;
  padding: 4px 8px;
  border-radius: 50px;

  &-light-success {
    background: lighten($color-success, 30%);
    color: darken($color-success, 20%);
  }

  &-light-warning {
    color: darken($color-warning, 30%);
    background-color: $color-warning;
  }

  &-success {
    background: $color-success-alt;
    color: #fff;
  }

  &-primary {
    background: $color-primary;
    color: #fff;
  }

  &-purple {
    background: $color-shopify-purple;
    color: #fff;
  }

  &-danger {
    background: $color-danger;
    color: #fff;
  }

  &-shopify-darkgreen {
    background: $color-shopify-darkgreen;
    color: #fff;
    &.badge-sm {
      font-size: 11px;
      padding: 6px 12px;
    }
  }
  &-xl {
    font-size: 20px;
  }
}

// TODO normalize color names to be more in line pf-button
.pf-badge {
  display: inline-block;
  vertical-align: middle;
  line-height: 15px;
  font-size: 12.8px;
  padding: 4px 8px;
  border-radius: 50px;
  text-align: center;

  &.xs {
    padding: 2px 6px;
    font-weight: 600;
  }

  &.success,
  &.green {
    background: $color-success-alt;
    color: #fff;
  }

  &.light-success {
    background: lighten($color-success, 30%);
    color: darken($color-success, 20%);
  }

  &.dark-success {
    background: #bbecd9;
    color: #333;
    font-weight: 400;
  }

  &.light-warning {
    color: darken($color-warning, 30%);
    background-color: $color-warning;

    &.outline {
      color: $color-warning;
      border: 1px solid $color-warning;
      background: none;
    }
  }

  &.white-warning {
    color: #fff;
    background-color: $color-warning;

    &.outline {
      color: $color-warning;
      border: 1px solid $color-warning;
      background: none;
    }
  }

  &.grey-warning {
    color: #fff;
    background-color: $color-secondary;

    &.outline {
      color: $color-secondary;
      border: 1px solid $color-secondary;
      background: none;
    }
  }

  &.dark-warning {
    background-color: #fae8c5;
    color: #333;
    font-weight: 400;
  }

  &.primary,
  &.blue {
    background: $color-primary;
    color: #fff;
  }

  &.purple {
    background: $color-shopify-purple;
    color: #fff;
  }

  &.danger,
  &.red {
    background: $color-danger;
    color: #fff;
  }

  &.grey {
    background-color: $color-secondary;
    color: #fff;
  }

  &.shopify-darkgreen {
    background: $color-shopify-darkgreen;
    color: #fff;
  }

  &.xl {
    font-size: 20px;
  }

  &.sm {
    font-size: 11px;
    padding: 6px 12px;
  }
}

.one-badge {
  display: inline-flex;
  padding: 2px 4px;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  align-items: center;

  &._blue {
    color: #009dff;
    background: #ebf8ff;
  }

  &._yellow {
    color: #f7b500;
    background: #fff7d9;
  }

  &._red {
    color: #ff4d4f;
    background: #fff1f0;
  }
}

